export const contracts_db = [
    {value: '124000', label: '124000 - Usine #22 St-Marc-des-Carrières'},
    {value: '124004', label: '124004 - Usine #178 St-Augustin-de-Desmaures'},
    {value: '124005', label: '124005 - Usine mobile #185'},
    {value: '124006', label: '124006 - Usine #200 Bécancour'},
    {value: '124007', label: '124007 - Usine Routek #187 Montréal'},
    {value: '124008', label: '124008 - Usine #221 St-Lambert-de-Lauzon'},
    {value: '221201', label: "221201 - Développement St-Apollinaire 2ème phase"},
    {value: '221216', label: "221216 - Développement Le Bourg, phase III"},
    {value: '222201', label: "222201 - Prolongement rue des Sables Développement SCJC inc."},
    {value: '223206', label: "223206 - Agrandissement de Transport Matte"},
    {value: '223207', label: "223207 - Reconstruction du seuil 29 et réhabilitation des voies de circulation Golf et Hôtel"},
    {value: '223209', label: "223209 - Notre-Dame-de-Montauban Réfection de la structure de la chaussée et du drainage de la route 367"},
    {value: '224210', label: "224210 - St-Raymond Réfection rue Ste-Claire, avenue St-Simon et réhabilitations diverses"},
    {value: '223211', label: "223211 - Québec Stationnement Irving - Midland Transport"},
    {value: "224212", label: "224212 - Prolongement de la butte Phase III St-Marc-des-Carrières"},
    {value: "224213", label: "224213 - Réf. RTE Bédard-CH Frenette, Ponceaux Rue Lesage, RG St-Antoine Saint-Léonard-de-Portneuf"},
    {value: '223214', label: "223214 - Accréditation d'entrepreneurs, Travaux d'entretien"},
    {value: '223215', label: "223215 - Augmentation de la capacité de l'égout sanitaire de la rue Plante"},
    {value: '223216', label: "223216 - Aménagement de nouvelles pistes cyclables (PAM220808)"},
    {value: '223217', label: "223217 - Travaux de réfection, Rues Saint-Victor et Saint-Charles"},
    {value: '223221', label: "223221 - Réfection des infrastructures souterraines et aménagement de la surface de la 18e Rue (PSO185434 et PAM210107)"},
    {value: '223223', label: "223223 - Remplacement de la conduite de refoulement d'égout sanitaire Établissement de Donnacona"},
    {value: '223224', label: "223224 - Déblai et pavage stationnement Motel sous les Charmilles"},
    {value: "224201", label: "224201 - Réfection des conduites pour la caserne"},
    {value: "224202", label: "224202 - Réfection canalisation sanitaire ph. 2 école des Trois-Sources Centre de services scolaire de Portneuf"},
    {value: "224203", label: "224203 - Réfection diverses rues - Primeau 2023 Ville de Portneuf"},
    {value: "224205", label: "224205 - Réfection de l'Avenue Gustave-Beaudet Ville de Québec"},
    {value: "224207", label: "224207 - Travaux de réfection et d'éclairage rue Jandomien Ville de l'Ancienne-Lorette VAL-325"},
    {value: "224208", label: "224208 - Québec Construction de nouvelles pistes cyclables "},
    {value: "224206", label: "224206 - RESA Seuil 06 Aéroport de Québec"},
    {value: '322028', label: "322028 - Développement Beau-Rivage, Phase 3"}, 
    {value: '324004', label: "324004 - Contrats de pavage divers "},
    {value: '323010', label: "323010 - Développement Umano, Phase 2"},
    {value: '323015', label: "323015 - Développement Boisé Natura, Phase 2"},
    {value: '323018', label: "323018 - Développement Albert-Rousseau, Phase 2"},
    {value: "323037", label: "323037 - Réfection boul. St-Maurice"},
    {value: "323038", label: "323038 - Pavage ponceaux rtes 367, 365, 354 et 363"},
    {value: "323045", label: "323045 - Pavage de la rue Principale"},
    {value: "323050", label: "323050 - Pavage rue Montcalm"},
    {value: "323054", label: "323054 - Pavage stationnement bâtiment Mercier 40"},
    {value: "323058", label: "323058 - Pont route 132 à Gentilly"},
    {value: "323060", label: "323060 - Pavage rues St-Antoine, St-Pierre, Tessier, Brunelle et Paquin"},
    {value: "323061", label: "323061 - Ponceaux rtes 263 et 265"},
    {value: "323064", label: "323064 - Pavage de la 4e Avenue"},
    {value: "323067", label: "323067 - Pavage, Collecteur d'égoût pluvial rue St-Omer"},
    {value: "323077", label: "323077 - Pavage du stationnement & chemin d'accès Maisons des aînés et maisons alternatives (SQI)"},
    {value: "323080", label: "323080 - Pavage - Zone de connexion - Parc Victoria"},
    {value: "323083", label: "323083 - Pavage des 2e et 3e rues"},
    {value: "324024", label: "324024 - Réfection rang du Brûlé et route Grand-Capsa"},
    {value: "324017", label: "324017 - Réfection voirie pavage St-François Warwick"},
    {value: "324019", label: "324019 - Donnacona Pavage Centre de données Microsoft - Rue Piché"},
    {value: "324025", label: "324025 - 6401-23-1002 Planage, décontamination et revêtement routes 955 et 78955"},
    {value: "324028", label: "324028 - 6406-22-0205 Pavage pont P-20325 sur le 12e et 13e rang ouest"},
    {value: "324022", label: "324022 - 7103-24-0903 - Planage, couche d'usure et pavage route 363 et rue Bourque"},
    {value: "324023", label: "324023 - Trois-Rivières Pavage Dév. des rues du Domaines et de l'Orée-Des-Bois"},
    {value: "324026", label: "324026 - Trois-Rivières Pavage des rues de la Loire et de la Garonne"},
    {value: "324027", label: "324027 - Trois-Rivières Pavage stationnement arrière du 2700, boul. des Forges"},
    {value: "324021", label: "324021 - Pavage 8e Rang Ouest Excavation Marcel Paradis inc."},
    {value: "324029", label: "324029 - Pavage rues du Pont et Goulet"},
    {value: "324034", label: "324034 - 6403-24-0905 Correction et couche d'usure sur l'aut. 30"},
    {value: "324031", label: "324031 - Pavage de la rue St-Calixte"},
    {value: "324033", label: "324033 - 7000002294 Réfection de pavage 2024"},
    {value: "324035", label: "324035 - Daveluyville 6003-23-0401 - Travaux modernisation halte routière Ste-Anne-du-Sault"},
    {value: "324038", label: "324038 - St-Basile Pavage d'un passage à niveau, boul. centenaire devant usine Ciment Québec"},
    {value: "324039", label: "324039 - 7007-22-0202 Pavage Pont P-19565 rue St-George"},
    {value: "324041", label: "324041 - Enrobé bitumineux - Divers segments Municipalité de Rivière-à-Pierre"},
    {value: "324055", label: "324055 - Plessisville Sécurisation boul. des Sucreries"},
    {value: "324051", label: "324051 - St-Léonard et Cap-Santé 7183-24-0906 - Préparation de surface, pavage et rechargement pour reconstruction ponceaux, routes 367 et 40621"},
    {value: "324058", label: "324058 - Nicolet Pavage rues du Centre-ville"},
    {value: "324059", label: "324059 - St-Gabriel-de-Valcartier Travaux de voirie municipale 2024 Boul. St-Sacrement & chemin Redmond"},
    {value: "324060", label: "324060 - Trois-Rivières 6000-16-057D-01 - Pavage boul. Ste-Madeleine"},
    {value: "324062", label: "324062 - Armagh Pavage du 8e Rang"},
    {value: "324066", label: "324066 - Pavage - Rue de la Suète Les Excavations Lafontaine inc."},
    {value: "324067", label: "324067 - Ste-Ursule Asphaltage rue Baril et coin Guérin/Principale"},
    {value: "324068", label: "324068 - Trois-Rivières Pavage stationnement Delta (Centre ville - 330, rue St-Roch)"},
    {value: "324069", label: "324069 - Pont-Rouge Pavage cour de l'usine - Pont-Rouge"},
    {value: "324070", label: "324070 - Lot H3 Pavage Louis-Riel, Parc industriel, Avenues des cendrées - Bécancour"},
    {value: "324072", label: "324072 - Honfleur Travaux de pavage 5e Rang"},
    {value: "324073", label: "324073 - Bécancour Travaux de pavage - Usine ABI"},
    {value: "324074", label: "324074 - Trois-Rivières Amén. avancées de trottoirs - Rue Notre-Dame & Père-Marquette"},
    {value: "324075", label: "324075 - St-Damase-de-L'Islet et St-Aubert 6603-24-0918 - Travaux préparatoires & asphaltage - Rte 204"},
    {value: "324076", label: "324076 - Donnacona Pavage deux stationnements ave. Cantin"},
    {value: "324077", label: "324077 - Bécancour 6406-21-0203 - Pavage pont rivière des Épinettes"},
    {value: "324078", label: "324078 - Pavage stationnement Bingo - Boul. St-Maurice A. Bouvet ltée"},
    {value: "324079", label: "324079 - St-Raymond Pavage École secondaire Louis-Jobin"},
    {value: "324080", label: "324080 - Réfection chemin de la tour du Lac Nord - Ville du Lac Sergent"},
    {value: "324081", label: "324081 - Pavage chemin des Mélèzes et du chemin des Hêtres Ville de Lac-Sergent"},
    {value: "324082", label: "324082 - Bécancour Pavage lot I - Traitement des eaux usées sanitaires - SPIPB"},
    {value: "324083", label: "324083 - Bécancour Pavage Lot J-E - Port de Bécancour"},
    {value: "324084", label: "324084 - Pont-Rouge Réparation de pavage -  Cour Sable Marco "},
    {value: "324085", label: "324085 - Pavage hangar quai 103 - QSL International ITÉE/ltd"},
    {value: "324086", label: "324086 - 7003240001 Ponceau 1087-O - Les Entreprise GNP inc."},
    {value: "324087", label: "324087 - Pavage rue Proulx - Excavation Marcel Paradis inc."},
    {value: "324088", label: "324088 - Pavage pont Trahan-Savoie, CH Petit-Chenal - Construction Lavigne & Baril inc."},
    {value: "324089", label: "324089 - Fourniture et mise en place de planage-Cour EDM et rtes des Commissaires - Construction Injection EDM inc."},
    {value: "324090", label: "324090 - Pavage  stationnement du poste de police - Excavation AD Roy inc."},
    {value: "324091", label: "324091 - Pavage du stationnement de l'usine et résidence - Nitek Laser inc"},
    {value: "324092", label: "324092 - Pavage rues Cordelles/l'Écorce - Les Excavation Yannick Latulippe"},
    {value: "324011", label: "324011 - 6603-24-0902, Enrobé et planage, Aut. 73 Nord"},
    {value: "324012", label: "324012 - Pavage Carrefour Pont-Rouge, Phase 5"},
    {value: "324013", label: "324013 - Pavage Développement Wendake Est - Phase 2"},
    {value: "324014", label: "324014 - 23-1042, Nouvelle conduite de refoulement dédiée à l'usine Saputo"},
    {value: "324015", label: "324015 - Lot H, Pavage sur Raoul-Duchêne, George-E. Ling et sud de l'Aut. 30 (Port de Bécancour)"},
    {value: "324016", label: "324016 - Pavage stationnement logement étudiant l'Utile"},
    {value: "324018", label: "324018 - Pavage développement résidentielle - Scierie Leduc - Phase 2A"},
    {value: "324020", label: "324020 - Pavage stationnement usine de cathode - (Ultium CAM)"},
    {value: "324032", label: "324032 - St-Basile et Neuville 7183-24-0902 - Planage et pose de couche d'usure sur diverses routes"},
    {value: "324037", label: "324037 - St-Maurice Pavage du rang St-Joseph"},
    {value: "324040", label: "324040 - 8601-22-0907 - Planage, pavage et divers travaux connexes Rte 00030-03-121 Ministère des Transports"},
    {value: "324042", label: "324042 - Réfection du Chemin Thomas-Maher - Secteur de la Chapelle Ville du Lac-St-Joseph"},
    {value: "324044", label: "324044 - Shawinigan Pavage nouveau bâtiment fédéral (NEGCS)"},
    {value: "324045", label: "324045 - Shannon, Pont-Rouge et St-Gabriel-de-Valcartier 7103-24-0902 - Rtes 365, 369, 371 et 42440"},
    {value: "324046", label: "324046 - Trois-Rivières Pavage Siemens rue Bellefeuille"},
    {value: "324048", label: "324048 - Ste-Angèle-de-Prémont Travaux d'aqueduc et de voirie - Rue St-Alfred"},
    {value: "324049", label: "324049 - St-Lambert-de-Lauzon Pavage Quartier des Familles - Phases 1.2 & 1.3 (rues Laliberté, Jolliet, Y et N)"},
    {value: "324050", label: "324050 - LYSTER Planage & pavage - Collecteur Bécancour Est  & rte 116"},
    {value: "324052", label: "324052 - Plessisville Pavage stationnement Centre Administratif"},
    {value: "324053", label: "324053 - Pont-Rouge Pavage rue Josephat-Martel"},
    {value: "324054", label: "324054 - St-Ubalde & St-Casimir 7103-24-0906 - Planage & pavage route 363"},
    {value: "324056", label: "324056 - Donnacona Pavage stationnement Canadian Tire"},
    {value: "324057", label: "324057 - Notre-Dame-du-Mont-Carmel Réfection diverses rues et stationnement garage municipal (Rgs St-Louis, St-Flavien, rue du Parc Industriel, ch. Lamothe"},
    {value: "324061", label: "324061 - Stoneham-et-Tewkesbury Pavage Mont Hibou - Chemins des Ruisselets & des Affluents"},
    {value: "324063", label: "324063 - Trois-Rivières Pavage stationnement Duguay - Ancien Tanguay"},
    {value: "324064", label: "324064 - Warwick Pavage rues St-Louis et Boulanger"},
    {value: "324065", label: "324065 - Pavage avenue Royale - St-Charles-de-Bellechasse"},
    {value: "795009", label: "795009 - Banc Drolet"},
    {value: "795017", label: "795017 - Sablière Pont-Rouge"},
    {value: "795019", label: "795019 - Sablière St-Gilbert"},
    {value: "700037", label: "700037 - Banc Fontaine"},
    {value: "702013", label: "702013 - Carrière Portneuf"},
    {value: "703014", label: "703014 - Banc Julien"},
    {value: "705016", label: "705016 - Banc St-Laurent"},
    {value: "706025", label: "706025 - Carrière Lessard"},
    {value: "707030", label: "707030 - Banc Tring-Jonction"},
    {value: "707031", label: "707031 - Banc Juneau"},
    {value: "708033", label: "708033 - Banc Serge Masse"},
    {value: "708034", label: "708034 - Sablière Ste-Christine"},
    {value: "710020", label: "710020 - Carrière KM 96"},
    {value: "713021", label: "713021 - Sablière Paquin"},
    {value: "713025", label: "713025 - Carrière Ste-Agathe"},
    {value: "714050", label: "714050 - Sablière Rivard"},
    {value: "714051", label: "714051 - Banc Noël"},
    {value: "715052", label: "715052 - Carrière Ste-Louise"},
    {value: "716048", label: "716048 - Sablière St-Apollinaire"},
    {value: "716049", label: "716049 - Carrière du Golf"},
    {value: "718031", label: "718031 - Inventaire sur terrain L'Islet"},
    {value: "718050", label: "718050 - Carrière Neuville"},
    {value: "718051", label: "718051 - Sablière Régie Neuville"},
    {value: "718054", label: "718054 - Sablière Régie St-Alban"},
    {value: "719008", label: "719008 - Carrière Deschambault"},
    {value: "720069", label: "720069 - Carrière Savard"},
    {value: "720077", label: "720077 - Sablière Guillaume Thibault"},
    {value: "721079", label: "721079 - Sablière Ste-Madelaine"},
    {value: "722082", label: "722082 - Aménagement terrain St-Antonin - GL 161151"},
    {value: "722081", label: "722081 - Terrain St-Antonin"},
    {value: "723084", label: "723084 - Carrière St-Prosper"},
    {value: "724006", label: "724006 - Travaux d'entretien CPPI"},
    {value: "724053", label: "724053 - Pavage Sartigan Ltée"},
    {value: "724007", label: "724007 - Formation main-d'oeuvre"},
    {value: "724084", label: "724084 - Aménagement cour Saint-Marc"},
    {value: '724005', label: '724005 - Fourniture contrats divers'},
    {value: '824012', label: "824012 - Garage St-Marc"},
    {value: '824013', label: "824013 - Garage Pont-Rouge"},
    {value: '824014', label: "824014 - Garage St-Ubalde"},
]