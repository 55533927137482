import axios from "axios";
import { isTokenExpired } from "../utils/jwt";
import { useNavigate } from "react-router-dom";
import { da } from "date-fns/locale";

const base_url = 'https://tempo-api.cppinc.ca/';
// const base_url = 'http://192.168.0.150:5000';

export async function fetchUsers() {
    try {
      let token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.get(base_url + '/users', config); // Replace with the API endpoint URL
      const data = response.data; // This will contain the fetched data
      return data; 
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // You can handle errors according to your app's requirements+
    }
  }


  export async function fetchReports() {
    try {
      
      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Tokens not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.get(base_url + '/timesheets', config); // Replace with the API endpoint URL
      const data = response.data; // This will contain the fetched data
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }

  export async function fetchReport(report_id) {
    try {

      let token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.get(base_url + '/timesheets/' + report_id, config); // Replace with the API endpoint URL
      const data = response.data; // This will contain the fetched data
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }


export async function fetchProfile() {
  try {

    let token = localStorage.getItem('token');
    
    if (!token) {
      throw new Error('Token not found');
    }

    if (isTokenExpired(token)) {
      await refreshToken();
      token = localStorage.getItem('token');
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
      },
    };

    const response = await axios.get(base_url + '/auth/profile', config); // Replace with the API endpoint URL
    const data = response.data; // This will contain the fetched data
    return data;

  } catch (error) {
    throw error;
  }
}

  export async function PostSignIn(payload) {
    try {
      const response = await axios.post(base_url + '/auth/login', payload); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending POST request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }

  async function refreshToken() {
    try {

      const refresh = localStorage.getItem('refresh');

      const config = {
        headers: {
          'Authorization': `Bearer ${refresh}`, // Add the JWT token as a Bearer token
        },
      };

      if (isTokenExpired(refresh)) {
        localStorage.clear();
        window.location.href = '/';
      }



      const response = await axios.get(base_url + '/auth/refresh', config); // Replace with the API endpoint URL
      const token = response.data.access_token; // This will contain the response data from the API
      localStorage.setItem('token', token);
    } catch (error) {
      console.error('Error sending POST request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }

  export async function DeleteUser(user_id) {
    try {

      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.delete(base_url + '/users/' + user_id, config); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending POST request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }

  export async function UpdateReport(timesheet_id, payload) {
    try {

      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const formData = new FormData();
      
      const files = payload.expenses.filter(item => item instanceof File);
      payload.expenses = payload.expenses.filter(item => typeof item === 'string')

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      formData.append('json', JSON.stringify(payload));

      const response = await axios.put(base_url + '/timesheets/' + timesheet_id, formData, config); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending PUT request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }


  export async function SaveFiles(files) {
    try {
      console.log(files)

      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const formData = new FormData();

      formData.append('file', files[0])

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.post(base_url + '/files', formData, config); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending PUT request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }




  export async function UpdateReportStatus(timesheet_id, payload) {
    try {

      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.patch(base_url + '/timesheets/' + timesheet_id, payload, config); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending POST request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }


  export async function UpdateReportApproval(timesheet_id, payload) {
    try {

      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.patch(base_url + '/timesheets/' + timesheet_id, payload, config); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending POST request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }



  export async function fetchReportSummary(report_id) {
    try {

      let token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.get(base_url + '/timesheets/' + report_id + '/summary', config); // Replace with the API endpoint URL
      const data = response.data; // This will contain the fetched data
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }


  export async function UpdateUserActive(user_id, payload) {
    try {

      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.patch(base_url + '/users/' + user_id, payload, config); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending POST request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }

  export async function UpdateUser(user_id, payload) {
    try {

      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      console.log(payload);
      const response = await axios.put(base_url + '/users/' + user_id, payload, config); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending POST request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }


  export async function CreateUser(payload) {
    try {

      let token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
      };

      const response = await axios.post(base_url + '/users', payload, config); // Replace with the API endpoint URL
      const responseData = response.data; // This will contain the response data from the API
      return responseData;
    } catch (error) {
      console.error('Error sending POST request:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }


  export async function fetchReportImport() {
    try {

      let token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Token not found');
      }

      if (isTokenExpired(token)) {
        await refreshToken();
        token = localStorage.getItem('token')
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the JWT token as a Bearer token
        },
        responseType: 'blob'
      };

      const response = await axios.get(base_url + '/timesheets/import', config); // Replace with the API endpoint URL

      const filename = response.headers.get('x-filename')
      const data = response.data; // This will contain the fetched data

      return { data, filename };
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // You can handle errors according to your app's requirements
    }
  }

